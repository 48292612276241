<template>
  <div class="unJoinMember">
    <a-select
      default-value="10"
      style="width: 200px;margin-right: 10px;margin-bottom: 20px"
      @change="handleChange"
    >
      <a-select-option value="10">
        一页显示10条
      </a-select-option>
      <a-select-option value="20">
        一页显示20条
      </a-select-option>
      <a-select-option value="30">
        一页显示30条
      </a-select-option>
      <a-select-option value="50">
        一页显示50条
      </a-select-option>
    </a-select>
    <!--<a-button type="primary" style="margin-bottom: 10px"  @click="distribution('more')">批量分配</a-button>-->
    <div v-if="un_data">
      <a-table :pagination="false" :data-source="un_data" bordered>
        <a-table-column key="sNumber" title="序号" data-index="sNumber" />
        <!--:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: conSelectChange }"-->
        <a-table-column key="action" title="会员名称">
          <template slot-scope="text, record">
            <a-avatar :size="24" :src="record.avatar" />&nbsp;&nbsp;
            {{ record.name ? record.name : record.remark }}
          </template>
        </a-table-column>
        <a-table-column key="UserName" title="手机号码">
          <div
            slot-scope="text, record"
            @click="_skipSkim(text.StudentId, text.StudentName)"
          >
            <a>{{ text.UserName }}</a>
          </div>
        </a-table-column>

        <a-table-column
          key="personName"
          title="添加人"
          data-index="personName"
        />
        <a-table-column
          key="createtime"
          title="添加时间"
          data-index="createtime"
        />
        <a-table-column key="remark" title="备注" data-index="remark" />
        <!--<a-table-column key="userid" title="操作" >-->
        <!--<template slot-scope="text, record">-->
        <!--<span>-->
        <!--<a @click="distribution(record.sNumber)">分配</a>-->
        <!--</span>-->
        <!--</template>-->
        <!--</a-table-column>-->
      </a-table>
    </div>

    <a-pagination
      v-if="pageTotal"
      style="margin-top: 35px;text-align: right;"
      @change="ChangePage"
      :pageSize="Limit"
      :total="pageTotal"
      show-less-items
    />
  </div>
</template>

<script>
export default {
  name: 'unJoinMember',
  data () {
    return {
      pageTotal: '',
      un_data: '',
      Limit: 10,
      selectedRowKeys: [],
      selectedRows: ''
    }
  },
  props: {
    partyid: {
      type: String
    },
    date: {
      type: String
    },
    name: {
      type: String
    },
    MenberRemark: {
      type: String
    }
  },
  created () {
    this.get_unassigned(0)
  },
  methods: {
    get_unassigned (page, partyid) {
      this.selectedRowKeys = []
      this.partyids = partyid
      let self = this
      let inGroup = 2
      this.$message.loading('加载中...')
      // if(this.SearchType ==3){
      //     inGroup =2;//未加群会员
      // }
      this.$axios.get(
        1117,
        {
          partyid: partyid ? partyid : self.partyid,
          date: self.date,
          name: self.name,
          page: page,
          limit: self.Limit,
          inGroup: inGroup,
          Remark: self.MenberRemark
        },
        res => {
          if (res.data.code == 1) {
            self.pageTotal = res.data.count
            self.un_data = res.data.list.list
            this.$emit(
              'tabData',
              res.data.list.chat_total,
              res.data.list.new_chat_cnt,
              res.data.list.un_add_count,
              res.data.list.un_dis_count,
              res.data.list.user_total,
              res.data.list.un_user_total,
              res.data.list.un_bind_user,
              res.data.list.bind_uaser_total
            )
          } else {
            self.un_data = ''
            self.pageTotal = ''
            // this.$emit('tabData',0,0,0,0,0,0,0,0);
            this.$message.error(res.data.msg)
          }
          this.$message.destroy()
        }
      )
    },
    ChangePage (value) {
      this.get_unassigned(value, this.partyids)
    },
    handleChange (value) {
      this.Limit = parseInt(value)
      this.get_unassigned(0, this.partyids)
    },
    //分配
    distribution (i, type) {
      let arr = []
      if (i == 'more') {
        if (!this.selectedRows) {
          this.$message.error('请先选择会员')
          return false
        }
        arr = this.selectedRows
        this.selectedRowKeys = []
        this.selectedRows = ''
      } else {
        let num = i - 1
        arr.push(this.un_data[num])
      }
      this.$emit('distribution', i, type, arr)
    },
    conSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    _skipSkim (id, name) {
      let data = {
        type: 'student',
        name: name,
        id: id
      }
      window.parent.postMessage(data, '*')
    }
  }
}
</script>

<style scoped></style>
