<template>
    <div class="groupList">
        <div class="tabs">
            <ul>
                <li @click="_tabsActive(0)" :class="tabsActive == 0 ? 'active' : ''">
                    群管理
                </li>
                <!--<li @click="_tabsActive(1)" :class="tabsActive==1?'active':''">群消息</li>-->
            </ul>
        </div>
        <div class="content">
            <div class="tree">
                <p class="company">公司组织架构</p>
                <a-tree
                        v-if="tabsActive == 0 && treeData"
                        :treeData="treeData"
                        @select="_treeSelect"
                />
            </div>
            <div class="list">
                <ul class="list-title">
                    <li
                            @click="_searchType(1)"
                            :class="SearchType == 1 ? 'title-active' : ''"
                    >
                        <span>总群数</span>
                        <span class="typeNum">{{ chat_total }}</span>
                    </li>
                    <li
                            @click="_searchType(5)"
                            :class="SearchType == 5 ? 'title-active' : ''"
                    >
                        <span>已加企业微信</span>
                        <span class="typeNum">{{ user_total }}</span>
                    </li>
                    <li
                            @click="_searchType(6)"
                            :class="SearchType == 6 ? 'title-active' : ''"
                    >
                        <span>未加企业微信</span>
                        <span class="typeNum">{{ un_user_total }}</span>
                    </li>
                    <!--<li @click="_searchType(2)" :class="SearchType==2?'title-active':''">-->
                    <!--<span>新建加群</span>-->
                    <!--<span class="typeNum">{{new_chat_cnt}}</span>-->
                    <!--</li>-->
                    <li
                            @click="_searchType(7)"
                            :class="SearchType == 7 ? 'title-active' : ''"
                    >
                        <span>已绑定已加入企业微信</span>
                        <span class="typeNum">{{ bind_uaser_total }}</span>
                    </li>
                    <li
                            @click="_searchType(8)"
                            :class="SearchType == 8 ? 'title-active' : ''"
                    >
                        <span>未绑定已加入企业微信</span>
                        <span class="typeNum">{{ un_bind_user }}</span>
                    </li>
                    <li
                            @click="_searchType(3)"
                            :class="SearchType == 3 ? 'title-active' : ''"
                    >
                        <span>未加群会员</span>
                        <span class="typeNum">{{ un_add_count }}</span>
                    </li>
                    <li
                            @click="_searchType(4)"
                            :class="SearchType == 4 ? 'title-active' : ''"
                    >
                        <span>离职未分配</span>
                        <span class="typeNum">{{ un_dis_count }}</span>
                    </li>
                </ul>
                <div style="clear: both"></div>
                <div class="search" style="display: block">
                    <a-date-picker
                            class="search-style"
                            @change="changeAddTime"
                            placeholder="添加日期"
                            style="width: 120px"
                    />
                    <a-input
                            v-model="name"
                            class="search-style"
                            placeholder="请输入员工姓名"
                            style="width: 120px"
                    />
                    <a-input
                            v-if="
              SearchType == 5 ||
                SearchType == 3 ||
                SearchType == 6 ||
                SearchType == 7 ||
                SearchType == 8
            "
                            v-model="MenberRemark"
                            class="search-style"
                            placeholder="请输入会员名称或备注"
                            style="width: 200px"
                    />
                    <a-button class="search-style" @click="clear_user_list"
                    >重置
                    </a-button
                    >
                    <a-button type="primary" @click="search_user_list">查询</a-button>
                    <!--<a-button v-if="SearchType ==4" type="primary" style="float: right" @click="distribution('more')">批量分配</a-button>-->
                </div>
                <!--总群数start-->
                <div v-if="(SearchType == 1 || SearchType == 4) && listData">
                    <a-table :pagination="false" :dataSource="listData" bordered>
                        <a-table-column key="sNumber" title="序号" data-index="sNumber"/>
                        <a-table-column key="Name" title="员工" data-index="username"/>
                        <a-table-column
                                key="schoolName"
                                title="部门"
                                data-index="ParentName"
                        />

                        <a-table-column
                                key="group_count"
                                :title="SearchType == 1 ? '群数' : '新建群数'"
                        >
                            <template slot-scope="text, record">
                <span>
                  <a @click="_groupList(record.UserName, record.username)">{{
                    record.group_count
                  }}</a>
                </span>
                            </template>
                        </a-table-column>
                        <a-table-column key="contact_count" title="客户数">
                            <template slot-scope="text, record">
                <span>
                  <a @click="_contactList(record.UserName, record.username)">{{
                    record.menber_num
                  }}</a>
                </span>
                            </template>
                        </a-table-column>
                        <a-table-column
                                key="UserState"
                                title="状态"
                                data-index="UserState"
                        />
                    </a-table>
                    <a-pagination
                            v-if="pageTotal"
                            style="margin-top: 35px;text-align: right;"
                            @change="ChangePage"
                            :pageSize="Limit"
                            :total="pageTotal"
                            show-less-items
                    />
                </div>
                <!--总群数end-->
                <!--已加企业微信-->
                <wechatJoin
                        @tabData="getMsgFormSon"
                        v-show="SearchType == 5"
                        ref="wechatJoin"
                        :MenberRemark="MenberRemark"
                        :partyid="partyid"
                        :name="name"
                        :date="date"
                ></wechatJoin>
                <!--未加企业微信-->
                <unWachat
                        v-show="SearchType == 6"
                        @tabData="getMsgFormSon"
                        ref="unWachat"
                        :MenberRemark="MenberRemark"
                        :partyid="partyid"
                        :name="name"
                        :date="date"
                ></unWachat>
                <!--未加群会员-->
                <unJoinMember
                        @tabData="getMsgFormSon"
                        @distribution="distribution"
                        v-show="SearchType == 3"
                        :MenberRemark="MenberRemark"
                        ref="unJoinMember"
                        :partyid="partyid"
                        :name="name"
                        :date="date"
                ></unJoinMember>
                <!--离职未分配-->
                <!--<unDistribution @tabData="getMsgFormSon" @distribution="distribution" :MenberRemark="MenberRemark" ref='unDistribution' v-show="SearchType==4" :partyid="partyid" :name="name" :date="date"></unDistribution>-->

                <!--加入企业微信未绑定-->
                <un_bind_user
                        v-show="SearchType == 8"
                        @tabData="getMsgFormSon"
                        :MenberRemark="MenberRemark"
                        ref="un_bind_user"
                        :partyid="partyid"
                        :name="name"
                        :date="date"
                ></un_bind_user>
                <!--已绑定并且已加入企业微信-->
                <bind_uaser_total
                        v-show="SearchType == 7"
                        ref="bind_uaser_total"
                        :MenberRemark="MenberRemark"
                        :partyid="partyid"
                        :name="name"
                        :date="date"
                ></bind_uaser_total>
            </div>
        </div>

        <!--群列表start-->
        <a-drawer
                :title="groupLeader ? groupLeader + '的群' : ''"
                width="1000"
                :closable="false"
                :visible="visible"
                @close="onClose"
        >
            <a-drawer
                    :title="group_info_title"
                    width="800"
                    :closable="false"
                    :visible="childrenDrawer_info"
                    @close="onChildrenDrawerClose"
            >
                <div style="margin-bottom: 60px">
                    <div class="search" style="margin-top: 0;display: block">
                        <a-input
                                class="search-style"
                                v-model="group_info_name"
                                placeholder="搜索群员"
                                style="width: 120px"
                        />
                        <a-button class="search-style" @click="_groupinfoClear"
                        >重置
                        </a-button
                        >
                        <a-button type="primary" @click="_groupinfoSearch">查询</a-button>
                    </div>
                    <a-table
                            v-if="group_list"
                            :data-source="group_list"
                            :pagination="false"
                            bordered
                    >
                        <a-table-column key="sNumber" title="序号" data-index="sNumber"/>
                        <a-table-column key="name" title="群成员" data-index="name"/>
                        <a-table-column key="groupleader" title="群主">
                            <div slot-scope="text, record">
                                {{ record.groupleader == 1 ? '是' : '否' }}
                            </div>
                        </a-table-column>
                        <a-table-column key="type" title="成员类型">
                            <template slot-scope="text, record">
                <span>
                  {{ record.type == 1 ? '企业成员' : '外部联系人' }}
                </span>
                            </template>
                        </a-table-column>
                        <a-table-column
                                key="join_scene"
                                title="入群方式"
                                data-index="join_scene"
                        />
                        <a-table-column
                                key="join_time"
                                title="入群时间"
                                data-index="join_time"
                        />

                        <!--<a-table-column key="notice" title="群备注" data-index="notice" />-->
                    </a-table>
                    <a-pagination
                            v-if="group_info_total"
                            style="margin-top: 35px;text-align: right;"
                            @change="_groupInfoPage"
                            :pageSize="Limit"
                            :total="group_info_total"
                            show-less-items
                    />
                </div>
                <div
                        :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'left',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px'
          }"
                >
                    <a-button type="primary" @click="onChildrenDrawerClose">
                        关闭
                    </a-button>
                </div>
            </a-drawer>

            <div style="margin-bottom: 60px">
                <div class="search" style="margin-top: 0;display: block">
                    <a-date-picker
                            class="search-style"
                            @change="changeAddTime"
                            placeholder="建群日期"
                            style="width: 120px"
                    />
                    <a-input
                            class="search-style"
                            v-model="groupName"
                            placeholder="搜索群名称"
                            style="width: 120px"
                    />
                    <a-select
                            :defaultValue="Limit"
                            style="width: 200px;margin-right: 10px"
                            :value="Limit"
                            @change="ChangeLimit"
                    >
                        <a-select-option value="10">
                            一页显示10条
                        </a-select-option>
                        <a-select-option value="20">
                            一页显示20条
                        </a-select-option>
                        <a-select-option value="30">
                            一页显示30条
                        </a-select-option>
                        <a-select-option value="50">
                            一页显示50条
                        </a-select-option>
                    </a-select>
                    <a-button class="search-style" @click="_groupClear">重置</a-button>
                    <a-button type="primary" @click="_groupSearch">查询</a-button>
                    <a-button
                            type="primary"
                            style="float:right;"
                            v-if="SearchType == 4"
                            @click="dis_group('more')"
                    >批量分配
                    </a-button
                    >
                </div>
                <!--:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: groupSelectChange }"-->
                <div v-if="groupData">
                    <a-table
                            :row-selection="
              SearchType == 4
                ? {
                    selectedRowKeys: selectedRowKeys,
                    onChange: groupSelectChange
                  }
                : ''
            "
                            :data-source="groupData"
                            :pagination="false"
                            bordered
                    >
                        <a-table-column key="sNumber" title="序号" data-index="sNumber"/>
                        <a-table-column key="name" title="群名称" data-index="name"/>
                        <a-table-column key="username" title="群主" data-index="username"/>
                        <a-table-column key="member_count" title="人数">
                            <template slot-scope="text, record">
                <span>
                  <!--@click="group_user(record.chat_id,record.name)"-->
                  {{ record.member_num }}
                </span>
                            </template>
                        </a-table-column>
                        <a-table-column
                                key="vs"
                                title="企业成员V外部联系人"
                                data-index="vs"
                        />
                        <!-- <a-table-column key="work_count" title="消息数量" data-index="work_count" /> -->
                        <a-table-column
                                key="create_time"
                                title="群的创建时间"
                                data-index="create_time"
                        />
                        <a-table-column key="notice" title="群备注" data-index="notice"/>

                        <a-table-column key="userid" title="操作">
                            <template slot-scope="text, record">
                <span>
                  <a @click="_group_info(record.chat_id, record.name)"
                  >群详情</a
                  >
                </span>
                                &nbsp;&nbsp;
                                <span v-if="SearchType == 4">
                  <a @click="dis_group(record.sNumber)">分配</a>
                </span>
                            </template>
                        </a-table-column>
                    </a-table>
                    <a-pagination
                            v-if="groupTotal"
                            style="margin-top: 35px;text-align: right;"
                            @change="_groupPage"
                            :pageSize="Limit"
                            :total="groupTotal"
                            show-less-items
                    />
                </div>
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="onClose">
                    取消
                </a-button>
                <a-button @click="dis_group('more')" type="primary">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--群列表end-->
        <!--客户列表start-->
        <a-drawer
                :title="con_name ? con_name + '的客户' : ''"
                width="1000"
                :closable="false"
                :visible="con_visible"
                @close="onClose"
        >
            <div style="margin-bottom: 60px">
                <div class="search" style="margin-top: 0;display: block">
                    <!--<a-date-picker class="search-style" @change="changeAddTime"  placeholder="建群日期" style="width: 120px" />-->
                    <a-input
                            class="search-style"
                            v-model="con_search_name"
                            placeholder="搜索客户名称或备注"
                            style="width: 200px"
                    />
                    <a-select
                            default-value="10"
                            style="width: 200px;margin-right: 10px"
                            @change="ChangeUserLimit"
                    >
                        <a-select-option value="10">
                            一页显示10条
                        </a-select-option>
                        <a-select-option value="20">
                            一页显示20条
                        </a-select-option>
                        <a-select-option value="30">
                            一页显示30条
                        </a-select-option>
                        <a-select-option value="50">
                            一页显示50条
                        </a-select-option>
                    </a-select>
                    <a-button class="search-style" @click="con_clear">重置</a-button>
                    <a-button type="primary" @click="con_search">查询</a-button>
                    <a-button
                            type="primary"
                            style="float:right;"
                            @click="distribution('more')"
                    >批量分配
                    </a-button
                    >
                </div>
                <!--:row-selection="conSelectChange" conSelectChange-->
                <a-table
                        v-if="con_data"
                        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: conSelectChange
          }"
                        :data-source="con_data"
                        :pagination="false"
                        bordered
                >
                    <a-table-column key="sNumber" title="序号" data-index="sNumber"/>

                    <a-table-column
                            key="StudentName"
                            title="会员名称"
                            data-index="StudentName"
                    >
                    </a-table-column>
                    <a-table-column
                            key="UserName"
                            title="手机号码"
                            data-index="UserName"
                    />
                    <a-table-column key="action" title="企业微信名称">
                        <template slot-scope="text, record">
                            <a-avatar :size="24" :src="record.avatar"/>&nbsp;&nbsp;
                            {{ record.name }}
                        </template>
                    </a-table-column>
                    <a-table-column
                            key="createtime"
                            title="添加时间"
                            data-index="createtime"
                    />
                    <a-table-column key="vs" title="备注" data-index="remark"/>
                    <!--<a-table-column key="work_count" title="最后一次分配人" data-index="last_add" />-->
                    <a-table-column key="userid" title="操作">
                        <template slot-scope="text, record">
              <span>
                <a @click="distribution(record.sNumber, 'con_data')">分配</a>
              </span>
                        </template>
                    </a-table-column>
                </a-table>
                <a-pagination
                        v-if="contactCount"
                        style="margin-top: 35px;text-align: right;"
                        @change="_conPage"
                        :pageSize="Limit"
                        :total="contactCount"
                        show-less-items
                />
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="con_Close">
                    取消
                </a-button>
                <a-button type="primary" @click="con_Close">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--客户列表end-->
        <!--群分配start-->
        <a-drawer
                :title="failListGroup ? '分配失败的群' : '分配信息'"
                width="520"
                :closable="false"
                :visible="childrenDrawer"
                @close="childrenClose"
        >
            <div style="margin-bottom: 60px">
                <div class="flex" style="margin-bottom: 20px;display: block">
                    <span style="width: 80px;display: inline-block">群名称</span>
                    <!--<a-input v-if="group_info.length>0" :value="group_info[0].name" />-->
                    <a-tag
                            v-if="group_info.length > 0"
                            style="margin-bottom: 10px"
                            v-for="item in group_info"
                    >{{ item.name }}
                    </a-tag
                    >
                </div>
                <div class="flex">
                    <span style="width: 80px;display: inline-block">群主</span>
                    <person v-bind="personObj" @getData="ChangeUser"></person>
                </div>
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="childrenClose">
                    取消
                </a-button>
                <a-button type="primary" @click="toGroup">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--群分配end-->
        <!--会员分配给个人start-->
        <a-drawer
                title="会员分配"
                width="520"
                :closable="false"
                :visible="dis_visible"
                @close="onClose"
        >
            <div style="margin-bottom: 60px">
                <div class="flex" style="margin-bottom: 20px;display: block">
                    <span style="width: 80px;display: inline-block">会员名称</span>
                    <a-tag
                            v-if="dis_data.length > 0"
                            style="margin-bottom: 10px"
                            v-for="item in dis_data"
                    >{{ item.name ? item.name : item.remark }}
                    </a-tag
                    >
                </div>
                <div class="flex">
                    <span style="width: 80px;display: inline-block">分配人1</span>
                    <person v-bind="personObj" @getData="ChangeUser"></person>
                </div>
            </div>

            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="dis_onClose">
                    取消
                </a-button>
                <a-button type="primary" @click="to_distribution">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <!--会员分配给个人end-->
    </div>
</template>

<script>
    import person from '../../components/SelectPersonnel/SelectPersonnel'
    import wechatJoin from 'pages/group/wechatJoin'
    import unJoinMember from 'pages/group/unJoinMember'
    import unDistribution from 'pages/group/unDistribution'
    import unWachat from 'pages/group/unWachat'
    import un_bind_user from 'pages/group/un_bind_user'
    import bind_uaser_total from 'pages/group/bind_uaser_total'
    import moment from 'moment'

    export default {
        name: 'groupList',
        components: {
            person,
            wechatJoin,
            unJoinMember,
            unDistribution,
            unWachat,
            un_bind_user,
            bind_uaser_total
        },
        data() {
            return {
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                tabsActive: 0,
                treeData: '',
                SearchType: 1,
                MenberRemark: '',
                chat_total: '', //总群数
                new_chat_cnt: '', //新增群数
                un_add_count: '', //未加群会员
                un_dis_count: '', //离职会员数
                user_total: '', // 已加企业微信
                un_user_total: '', //未加企业微信
                un_bind_user: '', // 加入企业微信未绑定8
                bind_uaser_total: '', //已绑定并且已加入企业微信7
                pageTotal: '',
                listData: '',
                date: '',
                name: '',
                partyid: '',
                Limit: 10,

                visible: false,
                groupData: '',
                groupName: '', //群名称
                groupLeader: '', //群主
                group_info: [], //群分配数据
                childrenDrawer: false,
                groupTotal: '',
                group_userid: '',
                groupPage: '',

                con_visible: false,
                con_name: '',
                con_data: '',
                contactCount: '',
                con_search_name: '',
                con_userid: '',
                con_page: '',

                /*群成员列表*/
                personObj: {
                    role: 0,
                    positions: 0,
                    rank: 0,
                    nums: 1, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: '', //常用联系人 不要常用联系人传空''
                    selectArr: '', //要传入的数据
                },
                takeover_userid: '', //接替成员的id
                selectedRowKeys: [],

                un_data: '',
                dis_visible: false,
                dis_data: [], //分配的数据
                takeover_userid: '', //接替成员的id

                group_list: '',
                childrenDrawer_info: false,
                group_info_total: '',
                group_info_index: '',
                group_info_page: '',
                group_info_name: '',
                group_info_title: '',
                failListGroup: ''
            }
        },
        mounted() {
            //组织架构
            this.$axios.get(1072, {}, res => {
                if (res.data.list) {
                    this.treeData = res.data.list
                } else {
                    this.$message.error('获取数据失败!')
                }
            })
        },
        computed: {},
        created() {
            this._loadData()
        },
        methods: {
            moment,
            _loadData() {
                let self = this
                this.$message.loading('加载中...')
                let UserState = ''
                if (this.SearchType == 4) {
                    UserState = 1
                }

                this.$axios.get(
                    1073,
                    {
                        partyid: self.partyid,
                        date: self.date,
                        name: self.name,
                        page: self.page,
                        Limit: self.Limit,
                        UserState: UserState
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            self.chat_total = res.data.list.chat_total //总群数
                            //self.new_chat_cnt= res.data.list.new_chat_cnt;//新增群数
                            self.un_add_count = res.data.list.un_add_count //未加群会员
                            self.un_dis_count = res.data.list.un_dis_count //离职会员数
                            self.user_total = res.data.list.user_total // 已加企业微信
                            self.un_user_total = res.data.list.un_user_total //未加企业微信
                            self.un_bind_user = res.data.list.un_bind_user
                            self.bind_uaser_total = res.data.list.bind_uaser_total
                            self.pageTotal = res.data.list.count
                            self.listData = res.data.list.list
                        } else {
                            self.chat_total = 0 //总群数
                            self.new_chat_cnt = 0 //新增群数
                            self.un_add_count = 0 //未加群会员
                            self.un_dis_count = 0 //离职会员数
                            self.user_total = 0 // 已加企业微信
                            self.un_user_total = 0 //未加企业微信
                            self.un_bind_user = 0
                            self.bind_uaser_total = 0
                            self.pageTotal = ''
                            self.listData = ''
                            this.$message.error(res.data.msg)
                        }

                        this.$message.destroy()
                    }
                )
            },
            getMsgFormSon(
                chat_total,
                new_chat_cnt,
                un_add_count,
                un_dis_count,
                user_total,
                un_user_total,
                un_bind_user,
                bind_uaser_total
            ) {
                this.chat_total = chat_total
                this.new_chat_cnt = new_chat_cnt
                this.un_add_count = un_add_count
                this.un_dis_count = un_dis_count
                this.user_total = user_total
                this.un_user_total = un_user_total
                this.un_bind_user = un_bind_user
                this.bind_uaser_total = bind_uaser_total
            },
            //群详情
            _group_info(chatid, name) {
                this.group_info_index = chatid

                this.group_info_title = name

                let self = this
                let data = {
                    chat_id: chatid,
                    page: self.group_info_page,
                    Limit: 10,
                    name: self.group_info_name
                }

                this.$message.loading('加载中...')
                this.$axios.get(1120, data, res => {
                    if (res.data.code == 1) {
                        self.groupTotal = res.data.count
                        self.group_list = res.data.data
                        self.childrenDrawer_info = true
                    } else {
                        this.$message.error(res.data.msg)
                    }
                    this.$message.destroy()
                })
            },
            _groupinfoClear() {
                this.group_info_page = ''
                this.group_info_name = ''
                this._group_info(this.group_info_index, this.group_info_title)
            },
            _groupInfoPage(value) {
                this.group_info_page = value
                this._group_info(this.group_info_index, this.group_info_title)
            },
            _groupinfoSearch() {
                this._group_info(this.group_info_index, this.group_info_title)
            },
            onChildrenDrawerClose() {
                this.group_list = ''
                this.group_info_page = ''
                this.group_info_name = ''
                ;(this.group_info_title = ''), (this.childrenDrawer_info = false)
            },
            //新建加群
            get_new_group_list() {
                let self = this
                this.$message.loading('加载中...')
                this.$axios.get(
                    1078,
                    {
                        partyid: self.partyid,
                        date: self.date,
                        name: self.name,
                        page: self.page
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.chat_total = res.data.list.chat_total //总群数
                            self.new_chat_cnt = res.data.list.new_chat_cnt //新增群数
                            self.un_add_count = res.data.list.un_add_count //未加群会员
                            self.un_dis_count = res.data.list.un_dis_count //离职会员数
                            self.user_total = res.data.list.user_total // 已加企业微信
                            self.un_user_total = res.data.list.un_user_total //未加企业微信
                            self.pageTotal = res.data.count
                            self.listData = res.data.list.list
                        } else {
                            self.chat_total = 0 //总群数
                            self.new_chat_cnt = 0 //新增群数
                            self.un_add_count = 0 //未加群会员
                            self.un_dis_count = 0 //离职会员数
                            self.user_total = 0 // 已加企业微信
                            self.un_user_total = 0 //未加企业微信
                            self.pageTotal = ''
                            self.listData = ''
                            this.$message.error(res.data.msg)
                        }
                        this.$message.destroy()
                    }
                )
            },
            search_user_list() {
                this.page = ''
                if (this.SearchType == 1) {
                    this._loadData()
                } else if (this.SearchType == 2) {
                    this.get_new_group_list()
                } else if (this.SearchType == 3) {
                    this.$refs.unJoinMember.get_unassigned(0)
                } else if (this.SearchType == 4) {
                    this._loadData()
                } else if (this.SearchType == 5) {
                    this.$refs.wechatJoin._user_total(0)
                    //this._user_total();
                } else if (this.SearchType == 6) {
                    this.$refs.unWachat.get_un_wachat(0)
                } else if (this.SearchType == 7) {
                    this.$refs.bind_uaser_total.get_bind_uaser_total(0)
                } else if (this.SearchType == 8) {
                    this.$refs.un_bind_user.get_un_bind_user(0)
                }
            },
            clear_user_list() {
                this.page = ''
                this.name = ''
                this.partyid = ''
                this.date = ''
                this.MenberRemark = ''
                if (this.SearchType == 1) {
                    this._loadData()
                } else if (this.SearchType == 2) {
                    this.get_new_group_list()
                } else if (this.SearchType == 3) {
                    this.$refs.unJoinMember.get_unassigned(0)
                } else if (this.SearchType == 4) {
                    this._loadData()
                } else if (this.SearchType == 5) {
                    this.$refs.wechatJoin._user_total(0)
                } else if (this.SearchType == 6) {
                    this.$refs.unWachat.get_un_wachat(0)
                } else if (this.SearchType == 7) {
                    this.$refs.bind_uaser_total.get_bind_uaser_total(0)
                } else if (this.SearchType == 8) {
                    this.$refs.un_bind_user.get_un_bind_user(0)
                }
            },
            ChangePage(value) {
                this.page = value
                if (this.SearchType == 1) {
                    this._loadData()
                } else if (this.SearchType == 2) {
                    this.get_new_group_list()
                } else if (this.SearchType == 3) {
                    this.$refs.unJoinMember.get_unassigned(0)
                } else if (this.SearchType == 4) {
                    this._loadData()
                } else if (this.SearchType == 5) {
                    this.$refs.wechatJoin._user_total(0)
                } else if (this.SearchType == 6) {
                    this.$refs.unWachat.get_un_wachat(0)
                } else if (this.SearchType == 7) {
                    this.$refs.bind_uaser_total.get_bind_uaser_total(0)
                } else if (this.SearchType == 8) {
                    this.$refs.un_bind_user.get_un_bind_user(0)
                }
            },

            _treeSelect(key, e) {
                this.partyid = e.selectedNodes[0].data.props.value
                if (this.SearchType == 1) {
                    this._loadData()
                } else if (this.SearchType == 2) {
                    this.get_new_group_list()
                } else if (this.SearchType == 4) {
                    this._loadData()
                } else if (this.SearchType == 5) {
                    //this._user_total();
                    this.$refs.wechatJoin._user_total(0, this.partyid)
                } else if (this.SearchType == 6) {
                    this.$refs.unWachat.get_un_wachat(0, this.partyid)
                } else if (this.SearchType == 7) {
                    this.$refs.bind_uaser_total.get_bind_uaser_total(0, this.partyid)
                } else if (this.SearchType == 8) {
                    this.$refs.un_bind_user.get_un_bind_user(0, this.partyid)
                }
            },
            _tabsActive(i) {
                this.tabsActive = i
            },
            _searchType(i) {
                this.SearchType = i
                this.page = ''
                this.pageTotal = 0
                if (i == 1) {
                    this._loadData()
                } else if (i == 2) {
                    this.get_new_group_list()
                } else if (i == 3) {
                    this.$refs.unJoinMember.get_unassigned(0)
                } else if (i == 4) {
                    this._loadData()
                } else if (i == 5) {
                    this.$refs.wechatJoin._user_total(0)
                } else if (i == 6) {
                    this.$refs.unWachat.get_un_wachat(0)
                } else if (i == 7) {
                    this.$refs.bind_uaser_total.get_bind_uaser_total(0)
                } else if (i == 8) {
                    this.$refs.un_bind_user.get_un_bind_user(0)
                }
            },
            changeAddTime(time, value) {
                this.date = value
            },
            ChangeUser(value) {
                this.takeover_userid = value[0].id
            },
            //群分配
            groupSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys
                this.group_info = selectedRows
            },
            dis_group(i) {
                if (i != 'more') {
                    let num = i - 1
                    this.group_info.push(this.groupData[num])
                } else {
                    if (this.group_info.length <= 0) {
                        this.$message.error('请先选择要分配的群')
                        return false
                    }
                }
                this.childrenDrawer = true
            },
            toGroup() {
                if (!this.takeover_userid) {
                    this.$message.error('请选择分配人')
                    return false
                }
                let self = this
                this.$message.loading('分配中...')
                this.$axios.post(
                    1077,
                    {
                        group_info: JSON.stringify(self.group_info),
                        new_owner: self.takeover_userid,
                        userid: self.userinfo.uid,
                        username: self.userinfo.name
                    },
                    res => {
                        this.$message.destroy()
                        if (res.data.code == 1) {
                            self.childrenClose()
                            self.$message.success(res.data.message)
                        } else {
                            self.failListGroup = res.data.fail
                            self.childrenClose()
                            self.$message.error(res.data.message)
                        }
                    }
                )
            },
            childrenClose() {
                this.personObj = {
                    role: 0,
                    positions: 0,
                    rank: 0,
                    nums: 1, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: '', //常用联系人 不要常用联系人传空''
                    selectArr: '', //要传入的数据
                };
                this.takeover_userid = ''
                this.group_info = []
                this.selectedRowKeys = []
                this.failListGroup = ''
                this.childrenDrawer = false
            },
            //群列表
            _groupList(userid, name) {
                this.$message.loading('加载中...')
                let self = this
                if (!this.groupLeader) {
                    this.groupLeader = name
                }
                if (!self.date) {
                    //新建群日期默认
                    if (self.SearchType == 2) {
                        self.date = this._currDate()
                    }
                }
                this.group_userid = userid

                this.$axios.get(
                    1076,
                    {
                        userid: userid,
                        date: self.date,
                        name: self.groupName,
                        status: self.SearchType,
                        page: self.groupPage,
                        limit: self.Limit
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.groupData = res.data.list
                            console.log(self.groupData)
                            self.groupTotal = res.data.count
                        } else {
                            self.groupData = ''
                            self.groupTotal = 0
                            this.$message.error(res.data.message)
                        }
                        this.$message.destroy()
                    }
                )
                this.visible = true
            },
            ChangeLimit(value) {
                this.Limit = parseInt(value)
                this._groupList(this.group_userid)
            },
            onClose() {
                this.date = ''
                this.groupName = ''
                this.groupPage = ''
                this.groupTotal = ''
                this.groupData = ''
                this.group_userid = ''
                this.groupLeader = ''
                this.selectedRowKeys = []
                this.Limit = 10
                this.visible = false
            },
            _groupClear() {
                this.date = ''
                this.groupName = ''
                this.groupPage = ''
                this._groupList(this.group_userid)
            },
            _groupSearch() {
                this.groupPage = ''
                this._groupList(this.group_userid)
            },
            _groupPage(value) {
                this.groupPage = value
                this._groupList(this.group_userid)
            },
            _currDate() {
                return this.__moment__().format('YYYY-MM-DD')
            },

            //客户列表
            _contactList(userid, name) {
                this.$message.loading('加载中...')
                if (!this.con_name) {
                    this.con_name = name
                }
                let date = null
                if (this.SearchType == 2) {
                    date = this.__moment__().format('YYYY-MM-DD')
                }

                this.con_userid = userid
                let self = this
                this.$axios.get(
                    1080,
                    {
                        userid: userid,
                        name: self.con_search_name,
                        page: self.con_page,
                        limit: self.Limit,
                        date: date
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            self.con_data = res.data.list
                            self.contactCount = res.data.count
                            self.con_visible = true
                        } else {
                            self.con_data = ''
                            self.contactCount = ''
                            this.$message.error(res.data.message)
                        }
                        this.$message.destroy()
                    }
                )
            },
            ChangeUserLimit(value) {
                this.Limit = parseInt(value)
                this._contactList(this.con_userid)
            },
            _conPage(value) {
                this.con_page = value
                this._contactList(this.con_userid)
            },
            con_clear() {
                this.con_search_name = ''
                this._contactList(this.con_userid)
            },
            con_search() {
                this.con_page = ''
                this._contactList(this.con_userid)
            },
            con_Close() {
                this.groupLeader = ''
                this.con_data = ''
                this.con_page = ''
                this.con_name = ''
                this.con_userid = ''
                this.con_search_name = ''
                this.con_visible = false
            },

            //分配
            conSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys
                this.dis_data = selectedRows
            },
            distribution(i, type, dis_data = '') {
                console.log(i, type, dis_data)
                if (dis_data) {
                    this.dis_data = dis_data
                }

                if (this.dis_data.length <= 0) {
                    if (i == 'more') {
                        if (this.dis_data.length <= 0) {
                            this.$message.error('请先选择要分配的会员')
                            return false
                        }
                    } else {
                        if (type == 'con_data') {
                            let num = i - 1
                            this.dis_data.push(this.con_data[num])
                            // this.con_visible = false;
                        } else {
                            this.dis_data = []
                            let num = i - 1
                            this.dis_data.push(this.un_data[num])
                        }
                    }
                }

                this.dis_visible = true
            },
            to_distribution() {
                let self = this
                if (!this.takeover_userid) {
                    this.$message.error('请选择分配人')
                    return false
                }
                this.$message.loading('分配中...')

                this.$axios.post(
                    1075,
                    {
                        external_info: JSON.stringify(self.dis_data),
                        takeover_userid: self.takeover_userid,
                        userid: self.userinfo.uid,
                        username: self.userinfo.name
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.dis_visible = false
                            self.takeover_userid = ''
                            self.dis_data = []

                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                        this.$message.destroy()
                    }
                )
            },
            ChangeUser(value) {
                this.takeover_userid = value[0].id
            },
            dis_onClose() {
                this.takeover_userid = ''
                this.dis_data = []
                this.selectedRowKeys = []
                this.dis_visible = false
            }
        }
    }
</script>

<style lang="less" scoped>
    * {
        font-family: Microsoft YaHei;
    }

    .tabs {
        border-bottom: 1px solid #ccd1dc;
        border-left: 1px solid #ccd1dc;
        border-top-left-radius: 5px;

        ul {
            li {
                display: inline-block;
                width: 85px;
                padding: 6px 0;
                text-align: center;
                border: 1px solid #ccd1dc;
                border-bottom: none;
                cursor: pointer;
                border-left: 0;
                &:first-child {
                    border-top-left-radius: 5px;
                }
                &:last-child {
                    border-top-left-radius: 0;
                    border-top-right-radius: 5px;
                }
            }
            .active {
                background: #1890ff;
                color: #fff;
                border: 1px solid #1890ff;
            }
        }
    }

    .content {
        margin-top: 15px;
        display: flex;
        min-height: 800px;
        .tree {
            width: 200px;
            background: #ffffff;
            padding: 15px;
            border: 1px solid #dfe0e6;
            border-radius: 5px;
            .company {
                color: #242424;
                font-size: 14px;
            }
        }
        .list {
            padding: 15px;
            background: #ffffff;
            margin-left: 20px;
            flex: 1;
            padding-right: 75px;
            .list-title {
                /*width: 400px;*/
                li {
                    width: 13.3%;
                    margin-right: 1%;
                    float: left;
                    border: 1px solid rgba(224, 234, 244, 1);
                    text-align: center;
                    border-radius: 5px;
                    padding: 10px 0px;
                    cursor: pointer;
                    span {
                        display: block;
                        font-size: 14px;
                        color: rgba(153, 153, 153, 1);
                    }
                    .typeNum {
                        color: rgba(102, 102, 102, 1);
                        font-size: 16px;
                    }
                }
                .title-active {
                    border: 1px solid rgba(41, 148, 255, 1);
                }
                li:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .search {
        display: flex;
        margin-top: 20px;
        margin-bottom: 25px;
        .search-style {
            margin-right: 10px;
        }
    }

    .hook {
        color: rgba(41, 148, 255, 1);
        cursor: pointer;
    }
</style>
