<template>
  <div class="unDistribution">
    <a-select
      default-value="10"
      style="width: 200px;margin-right: 10px"
      @change="handleChange"
    >
      <a-select-option value="10">
        一页显示10条
      </a-select-option>
      <a-select-option value="20">
        一页显示20条
      </a-select-option>
      <a-select-option value="30">
        一页显示30条
      </a-select-option>
      <a-select-option value="50">
        一页显示50条
      </a-select-option>
    </a-select>
    <a-button
      type="primary"
      style="margin-bottom: 10px"
      @click="distribution('more')"
      >批量分配</a-button
    >
    <a-table
      v-if="un_data"
      :pagination="false"
      :data-source="un_data"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: conSelectChange
      }"
      bordered
    >
      <a-table-column key="sNumber" title="序号" data-index="sNumber" />
      <a-table-column key="Name" title="员工" data-index="Name" />
      <a-table-column key="schoolName" title="部门" data-index="ParentName" />

      <a-table-column key="group_count" title="群数">
        <template slot-scope="text, record">
          <span>
            <a @click="_actionGroupList(record.UserName, record.username)">{{
              record.group_count
            }}</a>
          </span>
        </template>
      </a-table-column>
      <a-table-column key="contact_count" title="客户数">
        <!--<template slot-scope="text, record">-->
        <!--<span>-->
        <!--<a @click="_contactList(record.UserName,record.username)">{{record.menber_num}}</a>-->
        <!--</span>-->
        <!--</template>-->
      </a-table-column>
      <a-table-column key="UserState" title="状态" data-index="UserState" />
    </a-table>
    <a-pagination
      v-if="pageTotal"
      style="margin-top: 35px;text-align: right;"
      @change="ChangePage"
      :pageSize="Limit"
      :total="pageTotal"
      show-less-items
    />

    <!--群列表-->
    <!--<a-drawer-->
    <!--:title="groupLeader?groupLeader+'的群':''"-->
    <!--width="1000"-->
    <!--:closable="false"-->
    <!--:visible="visible"-->
    <!--@close="_onClose">-->
    <!--<div style="margin-bottom: 60px">-->
    <!--<div class="search" style="margin-top: 0;display: block">-->
    <!--&lt;!&ndash;<a-date-picker class="search-style" @change="changeAddTime"  placeholder="建群日期" style="width: 120px" />&ndash;&gt;-->
    <!--<a-input class="search-style" v-model="groupName" placeholder="搜索群名称" style="width: 120px" />-->
    <!--<a-select default-value="10" style="width: 200px;margin-right: 10px" @change="ChangeLimit">-->
    <!--<a-select-option value="10">-->
    <!--一页显示10条-->
    <!--</a-select-option>-->
    <!--<a-select-option value="20">-->
    <!--一页显示20条-->
    <!--</a-select-option>-->
    <!--<a-select-option value="30">-->
    <!--一页显示30条-->
    <!--</a-select-option>-->
    <!--<a-select-option value="50">-->
    <!--一页显示50条-->
    <!--</a-select-option>-->
    <!--</a-select>-->
    <!--<a-button class="search-style" @click="_groupinfoClear">重置</a-button>-->
    <!--<a-button type="primary" @click="_groupinfoSearch">查询</a-button>-->
    <!--<a-button type="primary" style="float:right;" @click="dis_group('more')">批量分配</a-button>-->
    <!--</div>-->
    <!--&lt;!&ndash;:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: groupSelectChange }"&ndash;&gt;-->
    <!--<a-table v-if="groupData"  :data-source="groupData" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: groupSelectChange }" :pagination="false" bordered >-->
    <!--<a-table-column key="sNumber" title="序号" data-index="sNumber" />-->
    <!--<a-table-column key="name" title="群名称" data-index="name" />-->
    <!--<a-table-column key="username" title="群主" data-index="username" />-->
    <!--<a-table-column key="member_count" title="人数" >-->
    <!--<template slot-scope="text, record">-->
    <!--<span>-->
    <!--&lt;!&ndash;@click="group_user(record.chat_id,record.name)"&ndash;&gt;-->
    <!--{{record.member_num}}-->
    <!--</span>-->
    <!--</template>-->
    <!--</a-table-column>-->
    <!--<a-table-column key="vs" title="企业成员V外部联系人" data-index="vs" />-->
    <!--&lt;!&ndash; <a-table-column key="work_count" title="消息数量" data-index="work_count" /> &ndash;&gt;-->
    <!--<a-table-column key="create_time" title="群的创建时间" data-index="create_time" />-->
    <!--<a-table-column key="notice" title="群备注" data-index="notice" />-->

    <!--<a-table-column key="userid" title="操作" >-->
    <!--<template slot-scope="text, record">-->
    <!--&lt;!&ndash;<span>&ndash;&gt;-->
    <!--&lt;!&ndash;<a @click="_group_info(record.chat_id,record.name)">群详情</a>&ndash;&gt;-->
    <!--&lt;!&ndash;</span>&ndash;&gt;-->
    <!--&lt;!&ndash;<span>&ndash;&gt;-->
    <!--&lt;!&ndash;<a @click="dis_group(record.sNumber)">分配</a>&ndash;&gt;-->
    <!--&lt;!&ndash;</span>&ndash;&gt;-->
    <!--</template>-->
    <!--</a-table-column>-->
    <!--</a-table>-->
    <!--<a-pagination v-if="groupTotal" style="margin-top: 35px;text-align: right;" @change="_groupPage" :pageSize="Limit" :total="groupTotal"  show-less-items />-->
    <!--</div>-->

    <!--<div-->
    <!--:style="{-->
    <!--position: 'absolute',-->
    <!--bottom: 0,-->
    <!--width: '100%',-->
    <!--borderTop: '1px solid #e8e8e8',-->
    <!--padding: '10px 16px',-->
    <!--textAlign: 'left',-->
    <!--left: 0,-->
    <!--background: '#fff',-->
    <!--borderRadius: '0 0 4px 4px',-->
    <!--}"-->
    <!--&gt;-->
    <!--<a-button style="marginRight: 8px" @click="_onClose">-->
    <!--取消-->
    <!--</a-button>-->
    <!--<a-button type="primary" @click="_onClose">-->
    <!--确认-->
    <!--</a-button>-->
    <!--</div>-->
    <!--</a-drawer>-->
  </div>
</template>

<script>
export default {
  name: 'unDistribution',
  data () {
    return {
      pageTotal: '',
      un_data: '',
      Limit: 10,
      selectedRowKeys: [],
      selectedRows: '',
      groupData: '',
      group_info: [], //群分配数据
      groupLeader: '',
      group_userid: '',
      groupTotal: '',
      groupName: '',
      groupPage: '',
      Limit: 10,
      visible: false
    }
  },
  created () {
    this.get_unassigned(0)
  },
  props: {
    partyid: {
      type: String
    },
    date: {
      type: String
    },
    name: {
      type: String
    },
    MenberRemark: {
      type: String
    }
  },
  methods: {
    get_unassigned (page, partyid) {
      this.partyids = partyid
      let self = this
      let inGroup = ''
      this.$message.loading('加载中...')
      this.$axios.get(
        1074,
        {
          partyid: partyid ? partyid : self.partyid,
          date: self.date,
          name: self.name,
          page: page,
          limit: self.Limit,
          inGroup: inGroup,
          Remark: self.MenberRemark
        },
        res => {
          if (res.data.code == 1) {
            self.pageTotal = res.data.count
            self.un_data = res.data.list.list
            this.$emit(
              'tabData',
              res.data.list.chat_total,
              res.data.list.new_chat_cnt,
              res.data.list.un_add_count,
              res.data.list.un_dis_count,
              res.data.list.user_total,
              res.data.list.un_user_total,
              res.data.list.un_bind_user,
              res.data.list.bind_uaser_total
            )
          } else {
            self.un_data = ''
            self.pageTotal = ''
            // this.$emit('tabData',0,0,0,0,0,0,0,0);
            this.$message.error(res.data.msg)
          }
          this.$message.destroy()
        }
      )
    },
    ChangePage (value) {
      this.get_unassigned(value, this.partyids)
    },
    handleChange (value) {
      this.Limit = parseInt(value)
      this.get_unassigned(0, this.partyids)
    },
    //分配
    distribution (i, type) {
      let arr = []
      if (i == 'more') {
        if (!this.selectedRows) {
          this.$message.error('请先选择会员')
          return false
        }
        arr = this.selectedRows
        this.selectedRowKeys = []
        this.selectedRows = ''
      } else {
        let num = i - 1
        arr.push(this.un_data[num])
      }
      this.$emit('distribution', i, type, arr)
    },
    conSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  },
  _actionGroupList (userid, name) {
    console.log(userid, name)
    this.$message.loading('加载中...')
    let self = this
    if (!this.groupLeader) {
      this.groupLeader = name
    }
    this.group_userid = userid

    this.$axios.get(
      1076,
      {
        userid: userid,
        date: self.date,
        name: self.groupName,
        page: self.groupPage,
        limit: self.Limit
      },
      res => {
        if (res.data.code == 1) {
          self.groupData = res.data.list
          self.groupTotal = res.data.count
        } else {
          self.groupData = ''
          self.groupTotal = 0
          this.$message.error(res.data.message)
        }
        this.$message.destroy()
      }
    )
    this.visible = true
  },
  groupSelectChange (selectedRowKeys, selectedRows) {
    this.selectedRowKeys = selectedRowKeys
    this.group_info = selectedRows
  },
  dis_group (i) {
    if (i != 'more') {
      let num = i - 1
      this.group_info.push(this.groupData[num])
    } else {
      if (this.group_info.length <= 0) {
        this.$message.error('请先选择要分配的群')
        return false
      }
    }

    this.childrenDrawer = true
  },
  _onClose () {
    this.date = ''
    this.groupName = ''
    this.groupPage = ''
    this.groupTotal = ''
    this.groupData = ''
    this.group_userid = ''
    this.groupLeader = ''
    this.selectedRowKeys = []
    this.Limit = 10
    this.visible = false
  }
}
</script>

<style scoped></style>
