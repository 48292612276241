<template>
  <div class="wechatJoin">
    <div>
      <a-table
        :pagination="false"
        v-if="user_total_data"
        :dataSource="user_total_data"
        bordered
      >
        <a-table-column key="sNumber" title="序号" data-index="sNumber" />
        <a-table-column key="action" title="客户名称">
          <template slot-scope="text, record">
            <a-avatar :size="24" :src="record.avatar" />&nbsp;&nbsp;
            {{ record.name }}
          </template>
        </a-table-column>
        <a-table-column
          key="StudentName"
          title="会员名称"
          data-index="StudentName"
        />

        <a-table-column key="UserName" title="手机号码">
          <div
            slot-scope="text, record"
            @click="_skipSkim(text.StudentId, text.StudentName)"
          >
            <a>{{ text.UserName }}</a>
          </div>
        </a-table-column>

        <a-table-column
          key="personName"
          title="添加人"
          data-index="personName"
        />
        <a-table-column key="gender" title="性别">
          <template slot-scope="text, record">
            <span>{{ record.gender == 1 ? '男' : '女' }}</span>
          </template>
        </a-table-column>
        <a-table-column key="add_way" title="客户的来源" data-index="add_way" />
        <a-table-column key="remark" title="备注" data-index="remark" />

        <a-table-column
          key="createtime"
          title="添加时间"
          data-index="createtime"
        />
      </a-table>
      <a-pagination
        v-if="pageTotal"
        style="margin-top: 35px;text-align: right;"
        @change="ChangePage"
        :pageSize="Limit"
        :total="pageTotal"
        show-less-items
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'groupList',
  data () {
    return {
      user_total_data: '',
      pageTotal: '',
      Limit: 10,
      partyids: ''
    }
  },
  props: {
    partyid: {
      type: String
    },
    date: {
      type: String
    },
    name: {
      type: String
    },
    MenberRemark: {
      type: String
    }
  },

  created () {
    this._user_total()
  },
  methods: {
    //总会员数
    _user_total (page, partyid) {
      this.partyids = partyid
      this.$message.loading('加载中...')
      let self = this
      this.$axios.get(
        '1084',
        {
          partyid: partyid ? partyid : self.partyid,
          date: self.date,
          name: self.name,
          page: page,
          Remark: self.MenberRemark
        },
        res => {
          if (res.data.code == 1) {
            self.pageTotal = res.data.list.count
            self.user_total_data = res.data.list.list
            this.$emit(
              'tabData',
              res.data.list.chat_total,
              res.data.list.new_chat_cnt,
              res.data.list.un_add_count,
              res.data.list.un_dis_count,
              res.data.list.user_total,
              res.data.list.un_user_total,
              res.data.list.un_bind_user,
              res.data.list.bind_uaser_total
            )
          } else {
            self.pageTotal = ''
            self.user_total_data = ''
            // this.$emit('tabData',0,0,0,0,0,0,0,0);
            this.$message.error(res.data.msg)
          }
          this.$message.destroy()
        }
      )
    },
    ChangePage (value) {
      this._user_total(value, this.partyids)
    },
    _skipSkim (id, name) {
      let data = {
        type: 'student',
        name: name,
        id: id
      }
      window.parent.postMessage(data, '*')
    }
  }
}
</script>

<style scoped></style>
