<template>
  <div class="bind_uaser_total">
    <a-select
      default-value="10"
      style="width: 200px;margin-right: 10px;margin-bottom: 20px"
      @change="handleChange"
    >
      <a-select-option value="10">
        一页显示10条
      </a-select-option>
      <a-select-option value="20">
        一页显示20条
      </a-select-option>
      <a-select-option value="30">
        一页显示30条
      </a-select-option>
      <a-select-option value="50">
        一页显示50条
      </a-select-option>
    </a-select>
    <!--<a-button type="primary" style="margin-bottom: 10px"  @click="distribution('more')">批量分配</a-button>-->
    <a-table v-if="un_data" :pagination="false" :data-source="un_data" bordered>
      <a-table-column key="sNumber" title="序号" data-index="sNumber" />
      <a-table-column
        key="StudentName"
        title="会员姓名"
        data-index="StudentName"
      />
      <!--:row-selection="onSelectChange"-->
      <a-table-column key="action" title="客户名称">
        <template slot-scope="text, record">
          <a-avatar :size="24" :src="record.avatar" />&nbsp;&nbsp;
          {{ record.name ? record.name : record.remark }}
        </template>
      </a-table-column>
      <a-table-column key="UserName" title="手机号码">
        <div
          slot-scope="text, record"
          @click="_skipSkim(text.StudentId, text.StudentName)"
        >
          <a>{{ text.UserName }}</a>
        </div>
      </a-table-column>
      <a-table-column key="Person" title="顾问" data-index="Person" />
      <a-table-column key="addName" title="添加人" data-index="addName" />
      <a-table-column
        key="createtime"
        title="添加时间"
        data-index="createtime"
      />
      <a-table-column key="remark" title="备注" data-index="remark" />
    </a-table>
    <a-pagination
      v-if="pageTotal"
      style="margin-top: 35px;text-align: right;"
      @change="ChangePage"
      :pageSize="Limit"
      :total="pageTotal"
      show-less-items
    />
  </div>
</template>

<script>
export default {
  name: 'bind_uaser_total',
  data () {
    return {
      pageTotal: '',
      un_data: '',
      Limit: 10,
      selectedRowKeys: [],
      selectedRows: ''
    }
  },
  created () {
    this.get_bind_uaser_total(0)
  },
  props: {
    partyid: {
      type: String
    },
    date: {
      type: String
    },
    name: {
      type: String
    },
    MenberRemark: {
      type: String
    }
  },
  methods: {
    get_bind_uaser_total (page, partyid) {
      this.partyids = partyid
      let self = this
      this.$message.loading('加载中...')
      this.$axios.get(
        1118,
        {
          partyid: partyid ? partyid : self.partyid,
          date: self.date,
          name: self.name,
          page: page,
          limit: self.Limit,
          Remark: self.MenberRemark
        },
        res => {
          if (res.data.code == 1) {
            self.pageTotal = res.data.count
            self.un_data = res.data.list
            this.$emit(
              'tabData',
              res.data.statistics.chat_total,
              res.data.statistics.new_chat_cnt,
              res.data.statistics.un_add_count,
              res.data.statistics.un_dis_count,
              res.data.statistics.user_total,
              res.data.statistics.un_user_total,
              res.data.statistics.un_bind_user,
              res.data.statistics.bind_uaser_total
            )
          } else {
            self.un_data = ''
            self.pageTotal = ''
            // this.$emit('tabData',0,0,0,0,0,0,0,0);
            this.$message.error(res.data.msg)
          }
          this.$message.destroy()
        }
      )
    },
    ChangePage (value) {
      this.get_unassigned(value, this.partyids)
    },
    handleChange (value) {
      this.Limit = parseInt(value)
      this.get_unassigned(0, this.partyids)
    },
    _skipSkim (id, name) {
      let data = {
        type: 'student',
        name: name,
        id: id
      }
      window.parent.postMessage(data, '*')
    },
    //分配
    distribution (i, type) {
      let arr = []
      if (i == 'more') {
        if (!this.selectedRows) {
          this.$message.error('请先选择会员')
          return false
        }
        arr = this.selectedRows
        this.selectedRowKeys = []
        this.selectedRows = ''
      } else {
        let num = i - 1
        arr.push(this.un_data[num])
      }
      this.$emit('distribution', i, type, arr)
    },
    conSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>

<style scoped></style>
