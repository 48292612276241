<template>
  <div class="unWachat">
    <a-table
      :pagination="false"
      v-if="un_wachat_data"
      :dataSource="un_wachat_data"
      bordered
    >
      <a-table-column key="sNumber" title="序号" data-index="sNumber" />
      <a-table-column
        key="StudentName"
        title="会员名称"
        data-index="StudentName"
      />
      <a-table-column key="UserName" title="手机号码">
        <div
          slot-scope="text, record"
          @click="_skipSkim(text.Id, text.StudentName)"
        >
          <a>{{ text.UserName }}</a>
        </div>
      </a-table-column>
      <a-table-column key="Person" title="顾问" data-index="person" />
      <a-table-column
        key="SchoolName"
        title="所属中心"
        data-index="SchoolName"
      />

      <a-table-column key="Sex" title="性别">
        <template slot-scope="text, record">
          <span>{{ record.Sex == 1 ? '男' : '女' }}</span>
        </template>
      </a-table-column>
    </a-table>
    <a-pagination
      v-if="pageTotal"
      style="margin-top: 35px;text-align: right;"
      @change="ChangePage1"
      :pageSize="Limit"
      :total="pageTotal"
      show-less-items
    />
  </div>
</template>

<script>
export default {
  name: 'unWachat',
  data () {
    return {
      Limit: 10,
      un_wachat_data: '',
      pageTotal: '',
      partyids: ''
    }
  },
  props: {
    partyid: {
      type: String
    },
    date: {
      type: String
    },
    name: {
      type: String
    },
    MenberRemark: {
      type: String
    }
  },
  created () {
    this.get_un_wachat(0)
  },
  methods: {
    get_un_wachat (page, partyid) {
      this.$message.loading('加载中...')
      this.partyids = partyid
      let self = this
      this.$axios.get(
        1116,
        {
          partyid: partyid ? partyid : self.partyid,
          date: self.date,
          name: self.name,
          page: page,
          Limit: self.Limit,
          Remark: self.MenberRemark
        },
        res => {
          this.$message.destroy()
          if (res.data.code == 1) {
            self.un_wachat_data = res.data.list
            self.pageTotal = res.data.count

            this.$emit(
              'tabData',
              res.data.statistics.chat_total,
              res.data.statistics.new_chat_cnt,
              res.data.statistics.un_add_count,
              res.data.statistics.un_dis_count,
              res.data.statistics.user_total,
              res.data.statistics.un_user_total,
              res.data.statistics.un_bind_user,
              res.data.statistics.bind_uaser_total
            )
          } else {
            self.un_wachat_data = ''
            self.pageTotal = ''
            // this.$emit('tabData',0,0,0,0,0,0,0,0);
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    ChangePage1 (value) {
      this.get_un_wachat(value, this.partyids)
    },
    _skipSkim (id, name) {
      let data = {
        type: 'student',
        name: name,
        id: id
      }
      window.parent.postMessage(data, '*')
    }
  }
}
</script>

<style scoped></style>
